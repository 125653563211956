* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  background: white;
  font-family: 'Poppins', sans-serif;
}

.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4rem);
}

.content {
  flex-grow: 1;
}

/* nav bar */

.nav {
  position: relative; /* Add this line */
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2em;
  padding: 0 4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.site-title {
  font-weight: 800;
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: center; /* Vertically align items */
  display: flex;
  gap: 2rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}

.nav > ul > li > .active {
  text-decoration: underline;
}

.nav li:hover {
  text-decoration: underline;
}

.user-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.createWillButton {
  background-color: #324b77;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;  
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.sub-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  flex-direction: column; /* Display sub-menu items vertically */
}

/* Styling for the submenu items */
.sub-menu li {
  list-style: none;
  margin: 0px 5px;
}

/* Styling for the submenu links */
.sub-menu li a {
  text-decoration: none;
  color: #333;
  display: block;
  border-radius: 10px;

  transition: background-color 0.2s ease;
}

.sub-menu li a:hover {
  background-color: #ddd;
}

.hamburger-button {
  display: none;
}

@media screen and (max-width: 837px) {
  .hamburger-button {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hamburger-button img {
    width: 2rem;
    height: 2rem;
  }

  .nav {
    padding: 0 1rem;
  }

  .nav ul {
    display: none;
  }

  .nav .sidebarActive {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 100%;
    padding-top: 3%;
    right: 0;
    width: 50%;
    height: 100vh;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
}

/* home page */

.home-container {
  display: flex;
  flex-direction: row;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: white;
  color: black;
}

.home-left {
  flex: 1;
  padding-left: 5%;
}

.home-left h1 {
  text-align: left;
}

.home-left p {
  text-align: left;
  padding-top: 1em;
  padding-bottom: 1em;
}

.home-right {
  flex: 1;
  display: flex;
  padding-right: 5%;
  align-content: center;
  flex-wrap: wrap;
}

.home-image-container {
  background: linear-gradient(white 50%, #232323 50%);
}

.home-image {
  max-width: 100%;
  height: auto;
  max-width: 342px;
  max-height: 303px;
}

.home-statistics {
  background-color: #232323;
  color: white;
  display: flex;
  justify-content: center;
}

.home-statistic {
  padding: 2%;
  padding-inline: 7%;
}

.home-container-offers {
  background-color: white;
  padding: 5%;
  display: flex;
}

.home-container-offers-left {
  width: 50%;
  text-align: left;
}

.home-container-offers-right {
  width: 50%
}

.home-container-quote {
  display: flex;
  flex-direction: column;
  background-color: #232323;
  padding: 5%;
  color: white;
}

.home-container-quote h1,
h2 {
  padding-bottom: 1em;
}

@media (max-width: 837px) {
  .home-container {
    flex-direction: column;
  }

  .home-left {
    padding: 0%;
    margin: 1rem;
  }

  .home-left h1 {
    text-align: center;
  }

  .home-right {
    padding-right: 0;
    margin: 1rem;
  }

  .home-container-offers {
    flex-direction: column;
    align-items: center;
  }

  .home-container-offers-left {
    width: 100%;
    padding: 5%;
  }

  .home-container-offers-right {
    width: 100%
  }
}
/* log in sign up styles */
.form-wider {
  width: 80%; /* Adjust the percentage to your desired value */
}
@media (min-width: 768px) {
  .form-wider {
    width: 30%;
  }
}

/* pop up styles */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup {
  max-width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.close-button:hover {
  color: #ccc;
}

/* principles page */

.principles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
}

.principles-container {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  width: calc(50% - 0.5rem);
}

.principles-container h2 {
  margin-bottom: -1rem;
}

@media (max-width: 768px) {
  .principles {
    flex-direction: column;
  }

  .principles-container {
    width: auto;
  }
}

/* @media (min-width: 768px) { */

.advisors-section h2 {
  padding-top: 2%;
}
/* } */
.about-us {
  display: flex;
  flex-direction: column; /* Change flex-direction to column */
  align-items: center;
}

.our-story {
  margin-bottom: 2rem; /* Add padding between each container rectangle*/
  border: 2px solid #ccc; /* Add border to draw a rectangle*/
  border-radius: 15px; /* Optional: Add border radius for rounded corners*/
  padding: 1rem; /* Optional: Adjust padding to make the rectangle smaller*/
  width: 90%;
  background: white;
  align-items: center; /* Center align items for mobile view */
  line-height: 1.6;
  margin: 0 auto;
}

.team-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Change flex-direction to column */
  align-items: center;
}

.team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member {
  display: flex;
  flex-direction: column; /* Change flex-direction to column */
  margin-bottom: 2rem;
  border: 2px solid #ccc;
  border-radius: 15px;
  padding: 1rem;
  width: 90%;
  background: white;
  align-items: center; /* Center align items for mobile view */
}

.team-member-description {
  padding: 40px; /* Add padding to the right and left side */
  display: flex;
  flex-direction: column; /* Align content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  flex: 1; /* Take up the full height of the parent container */

  /* Add padding for mobile devices */
  @media (max-width: 767px) {
    padding-top: 10px;
  }
}

.team-member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
  margin-left: 40px; /* Add margin to move the image more to the right */

  /* Add a media query for mobile view */
  @media (max-width: 767px) {
    margin-left: 0; /* Remove the left margin on mobile */
    margin-bottom: 0; /* Remove the bottom margin on mobile */
  }
}

.team-member-info {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  justify-content: center; /* Center align items vertically */
}

.team-member-info h3 {
  font-size: 18px;
  margin-bottom: 5px;

  /* Add margin to move the image more to the right on normal PCs */
  @media (min-width: 768px) {
    margin-left: 40px;
  }
  /* Add more margin on mobile devices */
  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

.team-member-info p {
  font-size: 16px;
}

@media (min-width: 768px) {
  .team-member {
    flex-direction: row; /* Revert to row for larger screens */
    align-items: flex-start; /* Align items to the left for larger screens */
  }

  .team-member-info {
    text-align: left; /* Align text to the left for larger screens */
  }
}

.our-story {
  text-align: center;
  margin-bottom: 20px;
}

.our-story h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.our-story p {
  font-size: 16px;
  line-height: 1.6;
}

/* footer */

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 1rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3);
  background-color: #324b77;
  flex-wrap: wrap;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

.content-container {
  flex: 1;
  box-sizing: border-box;
}

.footer {
  background-color: #232323;
  color: white;
}

/* Features */

.carousel-image {
  max-width: 60px;
  padding-bottom: 250px;
}

/* Reviews */

.reviews-container {
  display: flex;
  flex-direction: row;
  padding: 5%;
  text-align: left;
}

.reviews-container-left {
  width: 50%;
}

.reviews-container-right {
  width: 50%;
  display: flex;
  position: relative; 
}

.review-box {
  padding: 5%;
}

.reviews-container-right-right {
  background-color: #324b77;
  width: 90%;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 40px;
  right: 0;
  bottom: 0;
}

.reviews-container-right-left {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 90%;
  z-index: 2;
  background-color: white;
  height: 80%;
}

@media (max-width: 768px) {
  .reviews-container {
    flex-direction: column;
  }

  .reviews-container-right {
    width: 100%;
  }
  
  .reviews-container-left {
    padding: 5%;
    width: 100%;
  }
}