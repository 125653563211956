/* Add space between input and label */
.popup form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Style the input and label */
.popup input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.popup label {
  font-size: 16px;
  font-weight: bold;
}

/* Style the Submit and Close buttons */
.popup button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup button.close-button {
  background-color: #007bff;
}

/* Center the popup container */
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

/* Add these styles to your existing CSS or create a new CSS file */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 20px; /* Adjust this value as needed */
  height: 20px; /* Adjust this value as needed */
}

/* Optional: Style the "x" icon with an image */
.close-icon img {
  width: 75%;
  height: 75%;
}
.popup {
  position: relative; /* Set the popup container as the reference for absolute positioning */
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
}
